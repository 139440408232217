import { graphql, useStaticQuery } from 'gatsby';

import { Lang, SiteSettingsMap, WPImage, WPLink } from '@/src/types';

export type ICtaPopupCardData = {
    active: boolean;
    backgroundImage: WPImage;
    smallTitle: string;
    title: string;
    ctaLink: WPLink;
};

const useCtaPopupCardData = (lang: Lang): ICtaPopupCardData => {
  const data = useStaticQuery(graphql`
    {
      wp {
        siteSettingsSV {
            ctaPopupCard {
                active
                backgroundImage {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                quality: 100
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
                smallTitle
                title
                ctaLink {
                    target
                    title
                    url
                }
            }
        }
      }
    }
  `);

  return data.wp[SiteSettingsMap[lang]].ctaPopupCard;
};

export default useCtaPopupCardData;
