import * as React from "react";
import { ThemeProvider } from "styled-components";

import Seo from "@/components/_SEO_";
import Body from "@/components/core-blocks/Body";
import Footer from "@/components/core-blocks/Footer";
import Header from "@/components/core-blocks/Header";
import Main from "@/components/core-blocks/Main";
import Sidebar from "@/components/core-blocks/Sidebar";
import Flexible, { IComponent } from "@/components/imports/Flexible";
import GlobalStyle from "@/styles/global";
import { lightTheme } from "@/styles/themes/lightTheme";
import { Lang } from "@/src/types";
import { LanguageProvider } from "../contexts/LanguageContext";
import { Script } from "gatsby";
import CtaPopupCard from "@/components/core-blocks/CtaPopupCard/intex";

export type IPageContext = {
  pageContext: {
    uri: string;
    title: string;
    language: {
      code: Lang;
    };
    page_components: {
      components: ReadonlyArray<IComponent>;
    };
    seo: {
      title: string;
      focuskw: string;
      metaDesc: string;
      opengraphAuthor: string;
      opengraphTitle: string;
      opengraphDescription: string;
      opengraphImage: {
        sourceUrl: string;
      };
    };
  };
};

const PageTemplate: React.FC<IPageContext> = ({
  pageContext: { page_components, seo, language, title },
}: IPageContext) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <LanguageProvider lang={language.code}>
        <GlobalStyle />
        <Sidebar title={title} />
        <Body>
          <Header />
          <Main>
            <Seo
              title={seo.title}
              ogTitle={seo.opengraphTitle}
              description={seo.metaDesc}
              ogDescription={seo.opengraphDescription}
              author={seo.opengraphAuthor}
              image={seo.opengraphImage?.sourceUrl}
            />
            <Flexible components={page_components.components} />
            <CtaPopupCard />
          </Main>
          <Footer />
        </Body>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default PageTemplate;
