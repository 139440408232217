import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import button from "@/styles/button";
import text from "@/styles/text";

const Button = styled(button.btn_mini)`
    z-index: 3;
    width: fit-content;
    background-color: white !important;
    color: black !important;
    margin: 0;

`;

const Container = styled.div<{ isOpen: boolean }>`
    position: fixed;
    bottom: 50px;
    right: ${({ isOpen }) => (isOpen ? "0" : "-500px")};
    transition: all 0.5s ease-in-out;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    width: 350px;
    z-index: 1000;
    background-color: ${({ theme }) => theme.backgroundColorPurple};
    max-width: 95%;

`;

const ContentAsLink = styled.div`
    color: ${({ theme }) => theme.textSecondary};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        ${Button} {
            background-color: ${({ theme }) => theme.royalPurple} !important;

            color: ${({ theme }) => theme.textSecondary} !important;
        }
    }
`;

const Inner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const BackgroundImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
`;


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    padding: 33px 26px;
`;

const SmallTitle = styled(text.detail_text_mini)`
    z-index: 3;
    color: ${({theme}) => theme.lavender};
    margin-bottom: 20px;
`;

const Title = styled(text.heading_sm)`
    z-index: 3;
    color: ${({theme}) => theme.textSecondary};
    margin-bottom: 24px;

`;

const CloseButton = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 3;
    cursor: pointer;
    width: 45px;
    height: 45px;



    span {
        position: absolute;
        top: 20px; 
        right: 10px;
        width: 24px;
        height: 2px;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.textSecondary};
        transition: all 0.5s ease-in-out;
        &:first-child {
            transform: rotate(45deg);
        }
        &:last-child {
            transform: rotate(-45deg);
        }
    }
`;




const S = {
    Container,
    Inner,
    ContentAsLink,
    BackgroundImage,
    Wrapper,
    SmallTitle,
    Title,
    Link,
    CloseButton,
    Button,

};

export default S;